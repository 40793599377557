<template>
  <LoadScreen />
  <router-view v-if="Role != null" v-slot="{ Component }">
    <transition name="fade">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
import LoadScreen from './components/LoadScreen.vue'
import { mapState } from 'vuex'

export default{
  components: { LoadScreen },
  created(){
    let url = new URL(window.location.origin)
    if (url.port == 8080) url.port = 3999
    this.$store.commit('setUrl', url.origin + '/')
  },
  mounted() {
    this.$store.dispatch('checkAuth')
    document.head.querySelector('title').innerHTML = 'Тотализатор'
  },
  computed: {
    ...mapState(['Role'])
  },
  watch: {
    Role(val){
      if (val) {
        if (window.location.href.includes('/ratings'))
          this.$router.push('/ratings')
        else
          this.$router.push('/app')
      }
      else 
        this.$router.push('/login')
    }
  }
}
</script>

<style lang="sass">
body
  *, *::after, *::before
    box-sizing: border-box
    font-family: 'Open Sans', sans-serif

.fade-enter-active, .fade-leave-active
  transition: opacity .4s ease
.fade-enter-from, .fade-leave-to
  opacity: 0
  user-select: none
body
  margin: 0
  padding: 0
  ul, li
    margin: 0
    padding: 0
    list-style-type: none
</style>
