<template>
  <Transition name="fade">
    <div v-if="loading" class="loadscreen">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 100 100" :data-data="JSON.stringify($store.state.loads)">
        <circle cx="50" cy="50" r="20" stroke-width="8" stroke="#007bff" stroke-dasharray="31.41592653589793 31.41592653589793" fill="none" stroke-linecap="round">
          <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.25s" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
        </circle>
      </svg>
    </div>
  </Transition>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data: ()=>({
    active: false,
    timer: null
  }),
  computed: {
    ...mapGetters(['loading'])
  },
  watch: {
    loading(v) {
      if (v) {
        clearTimeout(this.timer)
        this.active = true
      } else {
        this.timer = setTimeout(()=>this.active = false, 200)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.loadscreen
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: 100%
  background: white
  z-index: 10000
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  svg
    width: 200px
    max-width: 90%
</style>