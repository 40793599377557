<template>
  <button class="btn" :class="{'btn_small':small, ['btn_' +color]:color}">
    <slot />
  </button>
</template>

<script>
  export default {
    props: {
      small: Boolean,
      color: String
    }
  }
</script>

<style lang="sass">
.btn
  font-weight: 600
  padding: 5px 15px
  background: darken(colors.$blue,20)
  border: none
  border-radius: 7px
  cursor: pointer
  display: block
  outline: none
  text-align: center
  color: white
  border-radius: 5px
  font-size: 20px
  align-items: center
  justify-content: center
  text-align: center
  text-shadow: -1px -1px 1px rgba(255,255,255,.5), 1px 1px 1px rgba(0,0,0,.5)
  svg
    width: 12px
    stroke: white
    fill: white
  &:hover, &:focus-visible
    background: darken(colors.$blue,25)
  &:active
    background: darken(colors.$blue,30)
  &_yellow
    background: colors.$yellow
    &:hover, &:focus-visible
      background: darken(colors.$yellow,15)
    &:active
      background: darken(colors.$yellow,25)
  &_green
    background: colors.$green
    &:hover, &:focus-visible
      background: darken(colors.$green,15)
    &:active
      background: darken(colors.$green,25)
  &_small
    font-size: 14px
    padding: 5px 10px
  
</style>