import { createRouter, createWebHistory } from 'vue-router'
import Ratings from '../views/Ratings.vue'
import Main from '../views/Main.vue'
import Admin from '../views/Admin.vue'
import LoginScreen from '../views/LoginScreen.vue'

const routes = [
  {
    path: '/app',
    name: 'Main',
    component: Main
  },
  {
    path: '/login',
    name: 'LoginScreen',
    component: LoginScreen
  },
  {
    path: '/ratings',
    name: 'Ratings',
    component: Ratings
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
