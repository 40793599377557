<template>
  <div class="main__content">
    <div class="main__panel">
      <div class="main__greeting">Привет, <b>{{ log }}</b>!</div>
      <Btn :small="true" color="green" @click="$router.push('/app')">К ставкам</Btn>
      <LogoutBtn />
    </div>
    <div class="admin__actions" v-if="Role == 2">
      <div class="admin__action">
        <h2>Загрузить из экселя</h2>
        <form @submit.prevent="loadData" ref="form">
          <input type="file" name="file" />
          <Btn :small="true" type="submit">Загрузить</Btn>
        </form>
      </div>
      <div class="admin__action">
        <h2>Добавить пользователя</h2>
        <form @submit.prevent="createUser">
          <input type="text" v-model="newUserLogin" placeholder="Логин" />
          <input type="password" v-model="newUserPassword" placeholder="Пароль" />
          <Btn :small="true" type="submit">Добавить</Btn>
        </form>
      </div>
      <div class="admin__action">
        <h2>Переименовать пользователя</h2>
        <form @submit.prevent="editUser">
          <input type="text" v-model="editUserLogin" placeholder="Логин" />
          <input type="text" v-model="editUserNewLogin" placeholder="Новый логин" />
          <Btn :small="true" type="submit">Переименовать</Btn>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LogoutBtn from '@/components/logoutBtn.vue'
import Btn from '@/components/Btn.vue'

export default {
  name: 'Admin',
  components: { LogoutBtn, Btn },
  data: () => ({
    newUserLogin: '',
    newUserPassword: '',
    editUserLogin: '',
    editUserNewLogin: ''
  }),
  methods: {
    async loadData() {
      const formData = new FormData(this.$refs.form)
      
      await fetch(this.url + 'bets', {
        method: 'POST',
        credentials: 'include',
        body: formData
      }).then(async res => {
        if (res.ok) {
          this.$refs.form.reset()
        } else {
          console.log(res.status)
          throw (await res.text())
        }
      }).catch(err => { alert(err); console.error(err);})
    },
    async createUser() {
      await fetch(this.url + 'user', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ login: this.newUserLogin, password: this.newUserPassword })
      }).then(async res => {
        if (res.ok) {
          this.newUserLogin = ''
          this.newUserPassword = ''
        } else {
          console.error(await res.text())
        }
      })
    },
    async editUser() {
      console.log(this.editUserLogin, this.editUserNewLogin)
      await fetch(this.url + 'user', {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ login: this.editUserLogin, newLogin: this.editUserNewLogin })
      }).then(async res => {
        if (res.ok) {
          this.editUserLogin = ''
          this.editUserNewLogin = ''
        } else {
          throw (await res.text())
        }
      }).catch(err => { alert(err); console.error(err);})
    }
  },
  computed: {
    ...mapState(['Role', 'log','url'])
  }
}
</script>

<style scoped lang="sass">
.admin__actions 
  display: flex
  gap: 20px
  flex-wrap: wrap

.admin__action 
  padding: 20px
  border: 1px solid #ccc
  border-radius: 10px
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1)
  display: flex
  flex-direction: column

  form
    display: grid
    grid-auto-columns: 1fr
    gap: 10px
    margin-top: auto
    button:last-child

.admin__action h2 
  font-size: 18px
  white-space: nowrap
  margin-top: 0

</style>