<template>
  <div class="main">
    <div class="main__content">
      <div class="main__panel">
        <div class="main__greeting">Привет, <b>{{ log }}</b>!</div>
        <Btn :small="true" color="green" @click="$router.push('/ratings')">К рейтингам</Btn>
        <Btn :small="true" color="green" @click="$router.push('/admin')" v-if="Role == 2">Админка</Btn>
        <LogoutBtn />
      </div>
      <MonthChoser />
      <div v-if="currentPlan">
        <div class="main__title">План на месяц: <strong>{{ numberWithSpaces(currentPlan) }}</strong> ₽</div>
      </div>
      <div class="main__tablewrap">
        <table class="main__table" v-if="filteredBetsObj && filteredBetsObj.length">
          <tr>
            <td></td>
            <td v-if="showDiff" style="font-size: 14px">До плана</td>
            <td style="font-size: 14px">Средняя<br> ставка</td>
            <td style="font-size: 14px">Число<br> ставок</td>
            <td v-for="day in filteredBetsObj" :key="day.date">{{ day.date.replace(/.....$/,'') }} {{ day.ofweek }}</td>
          </tr>
          <tr v-for="log in logs" :key="log">
            <td>{{ log }}</td>
            <td v-if="showDiff">{{ numberWithSpaces(dataPerUser[log].untilPlan) }}</td>
            <td>{{ dataPerUser[log].average }}</td>
            <td>{{ dataPerUser[log].betcount }}</td>
            <td v-for="day, idx in filteredBetsObj" :key="day.date">{{ day.table[log].bet || '-' }}
              <Star v-if="day.table[log].like" :level="getStarLevel(idx, log)"></Star>
              <Mistake v-if="day.table[log].dislike"></Mistake>
            </td>
          </tr>
          <tr>
            <td><b>Выручка</b></td>
            <td v-if="showDiff"></td>
            <td></td>
            <td></td>
            <td v-for="day in filteredBetsObj" :key="day.date"><b>{{ day.result }}</b></td>
          </tr>
        </table>
        <div v-else>Нет данных</div>
      </div>
      <div class="main__average">
        Средняя выручка за период: <b>{{ average }}</b>
      </div>
      <div v-if="!(betsObj && betsObj.length)">
        <div v-if="!(filteredBetsObj && filteredBetsObj.length)">
          Нет ставок в выбранном месяце
        </div>
        <div v-else>
          Ставок пока нет
        </div>
      </div>
      <div class="main__betmakers">
        <div class="betmaker" v-if="Role == 2">
          <div class="betmaker__title">Сделать ставку за пользователя</div>
          <div class="betmaker__content betmaker__content_wide">
            <label>
              <span>Пользователь</span>
              <select v-model="fbet.user">
                <option v-for="u in logs" :key="u" :value="u">{{ u }}</option>
              </select>
            </label>
            <label>
              <span>Ставка</span>
              <input type="number" v-model="fbet.bet"/>
            </label>
            <label>
              <span>Дата</span>
              <input type="text" placeholder="01.01.2020" v-model="fbet.date"/>
            </label>
            <Btn @click="()=>makeBet(fbet)" :small="true">Поставить</Btn>
          </div>        
        </div>
        <div class="betmaker">
          <div class="betmaker__title" v-if="Role == 1">Сделать ставку</div>
          <div class="betmaker__title" v-if="Role == 2">Утвердить выручку</div>
          <div class="betmaker__content">
            <label>
              <span v-if="Role == 1">Ставка</span>
              <span v-if="Role == 2">Выручка</span>
              <input type="number" v-model="bet.bet"/>
            </label>
            <label>
              <span>Дата</span>
              <input type="text" placeholder="01.01.2020" v-model="bet.date"/>
            </label>
            <Btn @click="()=>makeBet(bet)" :small="true" v-if="Role == 1">Поставить</Btn>
            <Btn @click="()=>makeBet(bet)" :small="true" v-if="Role == 2">Утвердить</Btn>
          </div>
        </div>
        <div class="betmaker" v-if="(Role == 2) && chosenmonth">
          <div class="betmaker__title" >План на {{ chosenmonth }}</div>
          <div class="betmaker__content">
            <label style="width: 100%">
              <span>&nbsp;</span>
              <input style="width: 100%" type="text" placeholder="∞" v-model="plan"/>
            </label>
            <Btn @click="this.$store.dispatch('setPlan', { chosenmonth, plan })" :small="true">Установить</Btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import LogoutBtn from '@/components/logoutBtn.vue'
import Btn from '@/components/Btn.vue'
import Star from '@/components/Star.vue'
import Mistake from '@/components/Mistake.vue'
import MonthChoser from '@/components/MonthChoser.vue'

export default {
  name: 'Main',
  components: { LogoutBtn, Btn, Star, Mistake, MonthChoser },
  data: ()=>({
    plan: 0,
    bet: {
      bet: 0,
      date: new Date().toLocaleDateString('fr-CH')
    },
    fbet: {
      user: '',
      bet: 0,
      date: new Date().toLocaleDateString('fr-CH')
    }
  }),
  mounted(){
    this.$store.dispatch('getBets')
    this.$store.dispatch('getPlans')
    if (!this.log) 
      this.$store.commit('setLog', this.getCookie('totalisatorlog'))
  },
  methods: {
    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    getStarLevel(idx, log){
      let res = 0
      if (idx == 0) return
      for (let i = idx - 1; i >= 0; i--) {
        if (!this.filteredBetsObj[i].table[log]) break
        if (this.filteredBetsObj[i].table[log].like) res++
        else break
      }
      return res
    },
    getCookie(name) {
          var nameEQ = name + "=";
          var ca = document.cookie.split(';');
          for(var i=0;i < ca.length;i++) {
              var c = ca[i];
              while (c.charAt(0)==' ') c = c.substring(1,c.length);
              if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
          }
          return null;
    }, 
    async makeBet(bet){
      this.$store.dispatch('makeBet', bet)
    }
  },
  computed: {
    ...mapState(['Role', 'log','chosenmonth','chosenday']),
    ...mapGetters(['betsObj', 'filteredBetsObj', 'winners', 'losers','logs','currentPlan','onlyMonthSelected']),
    showDiff(){
      console.log(this.onlyMonthSelected, this.currentPlan)
      return this.onlyMonthSelected && this.currentPlan
    },
    dataPerUser(){
      if (!this.filteredBetsObj || !this.filteredBetsObj.length) return 0
      let obj = {}
      for (let log of this.logs) {
        let res = 0, broken = 0
        for (let day of this.filteredBetsObj) {
          let v = day.table[log].bet
          if (parseInt(v) != v) broken++
          else res += v
        }
        let betcount = (this.filteredBetsObj.length - broken)
        let untilPlan = 0
        if (this.showDiff)
          untilPlan = this.currentPlan - res
        res /= betcount
        obj[log] = { average: Math.round(res) || '-', betcount, untilPlan }
      }
      return obj
    },
    average(){
      if (!this.filteredBetsObj || !this.filteredBetsObj.length) return 0
      let res = 0, broken = 0
      for (let day of this.filteredBetsObj) {
        if (parseInt(day.result) != day.result) broken++
        else res += day.result
      }
      res /= (this.filteredBetsObj.length - broken)
      return Math.round(res)
    }
  }
}
</script>
<style lang="sass">
.main
  &__title
    margin-bottom: 20px
    font-weight: 500
    font-size: 18px
  input, textarea, select
    outline: none
    &:focus-visible
      border-color: black
  table
    border-collapse: collapse
    tr:first-child td
      text-align: center
    td, th
      &.average
        font-size: 0.8em
      white-space: nowrap
      border: 1px solid rgba(0,0,0,.5)
      padding: 9px 12px
      &:not(:first-child)
        text-align: center
  padding: 5px
  position: relative
  min-height: 100vh
  background: url('~@/assets/bg.webp')
  background-repeat: repeat
  &::before
    content: ''
    position: absolute
    left:  0
    top: 0
    width: 100%
    height: 100%
    background: rgba(255,255,255,.5)   
    backdrop-filter: blur(2px)
  *
    position: relative
  &__average
    margin-top: 10px
    b
      display: inline-block
      margin-left: 5px
  &__content
    margin: 20px
  &__panel
    display: flex
    align-items: center
    gap: 10px
    margin-bottom: 20px
  &__greeting
    margin-right: 20px
  &__betmakers
    display: flex
    gap: 20px
  &__tablewrap
    width: 100%
    overflow: scroll
  &__month
    display: flex
    select
      margin-left: 10px
      border-radius: 5px
      outline: none !important
      border: 1px solid rgba(0,0,0,.3) !important
      padding-right: 3px
      padding-left: 2px
      margin-bottom: 10px      
  &__table
    tr:not(:first-child) 
      td:first-child
        position: sticky
        left: 0
        background: #f9f9f9 
        z-index: 1
    tr
      td:not(:first-child)
        &::before
          content: ''
          position: absolute
          left: -1px
          width: 1px
          height: 100%
          top: 0
          z-index: 2
          background: rgba(0,0,0,.1)


    td
      position: relative
      svg
        position: absolute
        width: 11px
        height: 11px
        top: 1px
        right: 1px
      svg + svg
        right: 12px
.betmaker
  display: flex
  flex-direction: column
  align-items: flex-start
  margin: 20px 0
  &__title
    font-weight: 600
    font-size: 20px
    margin-bottom: 5px
  &__content
    display: flex
    justify-content: space-between
    margin-bottom: 10px
    flex-wrap: wrap
    max-width: 270px
    &_wide
      max-width: 410px
    input, select
      border-radius: 5px
      outline: none
      border: 1px solid rgba(0,0,0,.5)
      padding: 4px 8px
      width: 130px
      height: 26px
      margin-bottom: 10px
    button
      width: 100%
  label
    &, span
      display: block

</style>