<template>
  <div class="main">
    <div class="main__content">
      <div class="main__panel">
        <div class="main__greeting">Привет, <b>{{ log }}</b>!</div>
        <Btn :small="true" color="green" @click="$router.push('/app')">К ставкам</Btn>
        <LogoutBtn />
      </div>
      <MonthChoser />
      <div class="main__ratings">
        <div class="rating">
          <table>
            <tr>
              <th>Имя</th>
              <th><Star /></th>
              <th><Mistake /></th>
              <th>Лучшая серия</th>
            </tr>
            <tr v-for="u in winners" :key="u.login">
              <!--eslint-disable-next-line-->
              <td>{{ u.login }} <span class="rating__arrow" :class="{ 'rating__arrow_up': u.shift < 0, 'rating__arrow_down': u.shift > 0 }">{{ u.shift?((u.shift < 0)? '↑' : '↓'):''}}</span></td>
              <td>{{ u.stars }}</td>
              <td>{{ losers[u.login] }}</td>
              <td>{{ winnersInRow[u.login] }}</td>
            </tr>
          </table>
        </div>
        <div class="rating">
          <table>
            <tr>
              <th>Имя</th>
              <th>Разница с выручкой</th>
              <th>Дата</th>
            </tr>
            <tr v-for="b in mostAccurate" :key="b.log + ' - ' + b.date">
              <td>{{ b.log }}</td>
              <td>{{ isNaN(b.value)? '-' : b.value }}</td>
              <td>{{ b.date }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import LogoutBtn from '@/components/logoutBtn.vue'
import Btn from '@/components/Btn.vue'
import Star from '@/components/Star.vue'
import Mistake from '../components/Mistake.vue'
import MonthChoser from '../components/MonthChoser.vue'

export default {
  name: 'Main',
  components: { LogoutBtn, Btn, Star, Mistake, MonthChoser },
  mounted(){
    if (!this.$store.bets)
      this.$store.dispatch('getBets')
    if (!this.log) 
      this.$store.commit('setLog', this.getCookie('totalisatorlog'))
  },
  methods: {
    getCookie(name) {
          var nameEQ = name + "=";
          var ca = document.cookie.split(';');
          for(var i=0;i < ca.length;i++) {
              var c = ca[i];
              while (c.charAt(0)==' ') c = c.substring(1,c.length);
              if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
          }
          return null;
    }
  },
  computed: {
    ...mapState(['Role', 'log']),
    ...mapGetters(['betsObj', 'winners', 'losers','logs','winnersInRow','mostAccurate'])
  }
}
</script>
<style lang="sass" scoped>
.main
  &__ratings
    display: flex
    gap: 50px
.rating
  &__arrow
    font-weight: 700
    &_up
      color: green
    &_down
      color: red
  th
    font-weight: 500
  table tr:nth-child(2) td
    font-weight: 700
  table td, table th
    padding: 10px 20px
    svg
      width: 20px
      height: 20px

</style>