<template>
  <div class="timechoser">
    <label class="main__month" v-if="months && months.length">
      <span>Выбрать месяц</span>
      <select :value="chosenmonth" @input="$store.commit('setChosenMonth', $event.target.value)">
        <option v-for="m in months" :key="m" :value="m">{{ m }}</option>
      </select>
    </label>
    <label class="main__month" v-if="months && months.length">
      <span>Выбрать день недели</span>
      <select :value="chosenday" @input="$store.commit('setChosenDay', $event.target.value)">
        <option v-for="d in ['', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс']" :key="d" :value="d">{{ d }}</option>
      </select>
    </label>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  methods: {
    //Converts a Date object to a string formatted as "MM.YYYY".
    dateToString(d) {
      return (d.getMonth() + 1 + '').padStart(2, '0') + '.' + d.getFullYear()
    },
    compareDates(date1, date2) {
      const [month1, year1] = date1.split('.');
      const [month2, year2] = date2.split('.');

      if (year1 !== year2) {
        return year1 - year2;
      } else {
        return month1 - month2;
      }
    }
  },
  computed: {
    ...mapState(['chosenmonth', 'chosenday']),
    ...mapGetters(['betsObj']),
    vals(){
      let obj = {}
      if (!this.betsObj || !this.betsObj.length) return []
      for (let day of this.betsObj) {
        let truncdate = day.date.replace(/^.../, '')
        obj[truncdate] = true
      }
      return Object.keys(obj)
    },
    months() {
      let today = new Date()
      let nextMonth = new Date(today.setMonth(today.getMonth() + 1))
      let defaults = ['', this.dateToString(today), this.dateToString(nextMonth)]
      if (!this.betsObj || !this.betsObj.length) return defaults
      let res = Array.from(new Set([...defaults, ...this.vals]))
      return res.sort((a, b) => this.compareDates(b, a))
    }
  },
  watch: {
    vals(v) {
      if (window.fired) return
      if (v?.length) {
        let today = this.dateToString(new Date())
        if (this.vals.includes(today)) {
          this.$store.commit('setChosenMonth', today)
        } else {
          let maxMonth = this.vals.reduce((max, current) => this.compareDates(max, current) > 0 ? max : current)
          this.$store.commit('setChosenMonth', maxMonth)
        }
        window.fired = true
      }
    }
  }
}
</script>

<style lang="sass">
.timechoser
  display: flex
  gap: 20px
  margin-bottom: 10px
</style>