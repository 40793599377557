<template>
  <svg :class="'l' + Math.min(level, 5)"  version="1.1" viewBox="-30 -30 1000.688 1000.688" xml:space="preserve"> <g> <path d="M885.344,319.071l-258-3.8l-102.7-264.399c-19.8-48.801-88.899-48.801-108.6,0l-102.7,264.399l-258,3.8   c-53.4,3.101-75.1,70.2-33.7,103.9l209.2,181.4l-71.3,247.7c-14,50.899,41.1,92.899,86.5,65.899l224.3-122.7l224.3,122.601   c45.4,27,100.5-15,86.5-65.9l-71.3-247.7l209.2-181.399C960.443,389.172,938.744,322.071,885.344,319.071z"/> </g> </svg>
</template>

<script>
  export default {
    props: {
      level: {type: Number, default: 0}
    }
  }
</script>

<style lang="sass" scoped>
svg
  fill: yellow
  stroke: black
  stroke-width: 50
  transform-origin: center center
  &.l1
    transform: scale(1.1)
    filter: contrast(1.1)
  &.l2
    transform: scale(1.2)
    filter: contrast(1.2)
  &.l3
    transform: scale(1.3)
    filter: contrast(1.3)
  &.l4
    transform: scale(1.4)
    filter: contrast(1.4)
  &.l5
    transform: scale(1.5)
    filter: contrast(1.5)
</style>